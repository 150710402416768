<script setup lang="ts">
import { platforms } from '~/constants/platforms.constants'
const props = defineProps<{
  platform: PLANN_PLATFORMS,
  mediaType: MediaType,
  showTooltip?: boolean
}>()

const computedPlatform = computed(() => {
  switch (props.platform) {
    case platforms.IG_BUSINESS:
    case platforms.IG_PERSONAL:
    case platforms.NUDE:
      if (props.mediaType === 'post') { return { icon: 'InstagramPostIcon', color: 'rgba(193, 53, 132, 0.08)' } }
      if (props.mediaType === 'reel') { return { icon: 'InstagramReelsIcon', color: 'rgba(193, 53, 132, 0.08)' } }
      if (props.mediaType === 'story') { return { icon: 'InstagramStoryIcon', color: 'rgba(193, 53, 132, 0.08)' } }
      return { icon: 'InstagramPostIcon', color: 'rgba(193, 53, 132, 0.08)' }
    case platforms.FB:
      return { icon: 'FacebookIcon', color: 'rgba(66, 103, 178, 0.08)' }
    case platforms.LINKEDIN_PAGES:
    case platforms.LINKEDIN_PERSONAL:
      return { icon: 'LinkedinIcon', color: 'rgba(40, 103, 178, 0.08)' }
    case platforms.PINTEREST:
      return { icon: 'PinterestIcon', color: 'rgba(230, 0, 35, 0.08)' }
    case platforms.TIKTOK:
      return { icon: 'TikTokIcon', color: 'rgba(0, 0, 0, 0.08)' }
    case platforms.THREADS:
      return { icon: 'ThreadsIcon', color: 'rgba(0, 0, 0, 0.08)' }
    default:
      return { icon: '', color: 'rgba(0, 0, 0, 0.08)' }
  }
})
</script>

<template>
  <div class="group flex size-[40px] items-center justify-center rounded-xl bg-gray-200" :style="{background: computedPlatform.color}" data-tooltip>
    <Icon :name="computedPlatform.icon" size="1.25em" />
    <div v-if="showTooltip && platform === platforms.IG_BUSINESS" role="tooltip" class="duration-2 tooltip invisible absolute z-10 mb-3 inline-block -translate-y-full rounded-lg bg-plann-blue-dark-500 px-3 py-2 text-sm font-medium  capitalize text-white opacity-0 shadow-sm transition-opacity after:border-plann-blue-500 group-hover:visible group-hover:opacity-100">
      Instagram {{ mediaType }}
    </div>
  </div>
</template>
<style>
.tooltip::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #04446B transparent transparent transparent;
  background-color: transparent;
}
</style>
