<script setup lang="ts">
const videoRef: Ref<null | HTMLVideoElement> = ref(null)
const showPlayButton = ref(false)

defineProps<{
  src: string,
  thumbnail?: string,
  disable?: Boolean,
}>()

function playVideo () {
  if (!videoRef.value) {
    return
  }

  const isVideoPlaying = (video: HTMLVideoElement) => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
  if (isVideoPlaying(videoRef.value)) {
    videoRef.value.pause()
    showPlayButton.value = false
  } else {
    videoRef.value.play()
    showPlayButton.value = true
  }
}

onMounted(() => {
  if (videoRef.value) {
    videoRef.value.addEventListener('play', () => {
      console.log('play')
      showPlayButton.value = true
    })
    videoRef.value.addEventListener('pause', () => {
      showPlayButton.value = false
    })
  }
})

onBeforeUnmount(() => {
  if (videoRef.value) {
    videoRef.value.removeEventListener('play', () => {
      showPlayButton.value = true
    })
    videoRef.value.removeEventListener('pause', () => {
      showPlayButton.value = false
    })
  }
})
</script>

<template>
  <div class="relative h-full w-full">
    <button v-if="showPlayButton === false" class="absolute left-[calc(50%-25px)] top-[calc(50%-25px)] z-10 flex h-[50px] w-[50px] transition duration-200" @click="playVideo">
      <div class="0 m-auto flex h-[50px] w-[50px] rounded-full bg-black/40">
        <Icon name="PlayIcon" size="1.5em" class="m-auto" />
      </div>
    </button>
    <div v-if="disable" :style="{'background-image': `url('${thumbnail}')`}" class="m-auto h-full w-full rounded-lg bg-blue-200 bg-cover bg-center bg-no-repeat" />
    <video
      v-else
      ref="videoRef"
      class="max-h-70vh m-auto h-full w-full rounded-lg"
      muted
      controls
      :src="src"
    />
  </div>
</template>
