<script setup lang="ts">
// @ts-ignore
import emblaCarouselVue from 'embla-carousel-vue'
defineEmits(['onClicked'])

const activePosition = ref(0)

defineExpose({
  activePosition
})

const [emblaNode, emblaApi] = emblaCarouselVue()

const props = withDefaults(defineProps<{
  medias?: MediaElement[] | null
  contentMediaClass?: string,
  disableVideo?: Boolean,
  pageStart?: number,
}>(),
{
  medias: null,
  contentMediaClass: 'aspect-square'
})

// @ts-ignore
const onSelect = (emblaApi /* eventName */) => {
  activePosition.value = emblaApi.selectedScrollSnap()
}

watchEffect(() => {
  if (emblaApi.value) { emblaApi.value.on('select', onSelect) }
})

const isCurrentPageVideo = computed(() => {
  const media = props.medias && props.medias[activePosition.value]

  if (media) {
    return media.is_video === true
  }
  return false
})

function nextSlide () {
  emblaApi.value?.scrollNext()
}
function prevSlide () {
  emblaApi.value?.scrollPrev()
}

onMounted(() => {
  if (props.pageStart) {
    emblaApi.value?.scrollTo(props.pageStart, true)
    activePosition.value = props.pageStart
  }
})

</script>

<template>
  <div id="default-carousel" class="relative flex w-full flex-col" data-carousel="static">
    <!-- Carousel wrapper -->
    <div class="relative h-full overflow-hidden rounded-lg">
      <!-- Item 1 -->
      <div ref="emblaNode" class="embla">
        <div class="embla__container">
          <div v-for="(media, i) in medias" :key="'image'+i" class="embla__slide bg-white" @click="$emit('onClicked', i)">
            <ContentMedia :media="media" :disable-video="disableVideo" :class="contentMediaClass" />
          </div>
        </div>
      </div>
    </div>
    <!-- Slider indicators -->
    <div v-if="!isCurrentPageVideo || disableVideo" class="absolute bottom-5 left-1/2 z-20 flex -translate-x-1/2 space-x-3 font-semibold">
      <div class="rounded-3xl bg-black/20 px-4 py-2 text-sm">
        <div class="text-white">
          {{ activePosition + 1 }}/{{ (medias || []).length }}
        </div>
      </div>
    </div>
    <!-- Slider controls -->
    <button v-if="activePosition !== 0" type="button" class="group absolute left-0 top-[calc(50%-20px)] z-20 flex h-auto cursor-pointer items-center justify-center px-4 focus:outline-none" @click="() => prevSlide()">
      <span class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-black/20 group-hover:bg-black/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-black/20 dark:group-hover:bg-gray-800/60 sm:h-10 sm:w-10">
        <svg
          aria-hidden="true"
          class="h-5 w-5 text-white dark:text-gray-800 sm:h-6 sm:w-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        ><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" /></svg>
        <span class="sr-only">Previous</span>
      </span>
    </button>
    <button v-if="activePosition + 1 < (medias || []).length" type="button" class="group absolute right-0 top-[calc(50%-20px)] z-20 flex h-auto cursor-pointer items-center justify-center px-4 focus:outline-none" @click="() => nextSlide()">
      <span class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-black/20 group-hover:bg-black/50 group-focus:outline-none group-focus:ring-4 group-focus:ring-black/20 dark:group-hover:bg-gray-800/60 sm:h-10 sm:w-10">
        <svg
          aria-hidden="true"
          class="h-5 w-5 text-white dark:text-gray-800 sm:h-6 sm:w-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        ><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" /></svg>
        <span class="sr-only">Next</span>
      </span>
    </button>
  </div>
</template>

<style>
.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
  margin-right: 5px;
  margin-left: 5px;
}</style>
